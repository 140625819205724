import React, { useEffect, useState } from 'react';
import { DatePicker, Table, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import { findPayPeriodDates } from './../../../utils/moment-utils';
import { currencyFormatter, percentageFormatter } from './../../../utils/formatters';
import { fetchMonthlyAuditDataAction } from './../../../ducks/reports';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  TitleH4,
  GridRow,
  CardNoTitle,
  PrimaryButton,
  AvatarCard,
} from './../../../components';
import { COLORS } from './../../../constants/styles';

const { RangePicker } = DatePicker;

const MonthlyFeeAudit = ({
  fetchMonthlyAuditData,
  reports: {
    monthlyAuditData: { companyLevelData, monthlyClientData },
    loading,
  },
}) => {
  const [dateRange, setDateRange] = useState(findPayPeriodDates(moment(), true));
  const [rangeChanged, setRangeChanged] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    if (dateRange.length > 0) {
      fetchMonthlyAuditData({
        startDate: moment(dateRange[0]).startOf('day').utc().format(),
        endDate: moment(dateRange[dateRange.length - 1])
          .endOf('day')
          .utc()
          .format(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openChange = (status) => {
    if (!status && rangeChanged) {
      setPickerOpen(true);
    } else {
      setPickerOpen(status);
    }
  };

  const onChange = (range) => {
    if (
      !moment(range[0]).isSame(moment(dateRange[0])) ||
      !moment(range[1]).isSame(moment(dateRange[1]))
    ) {
      setDateRange(range);
      setPickerOpen(true);
      setRangeChanged(true);
    }
  };

  const onOk = () => {
    setRangeChanged(false);
    setPickerOpen(false);
    fetchMonthlyAuditData({
      startDate: moment(dateRange[0]).startOf('day').utc().format(),
      endDate: moment(dateRange[dateRange.length - 1])
        .endOf('day')
        .utc()
        .format(),
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => record.name,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: () => (
        <div>
          First Entry
          <Tooltip
            placement="topRight"
            title="First entry within the specified date range. May contain a sooner entry outside of the specified date range."
          >
            <QuestionCircleFilled style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'firstDate',
      key: 'firstDate',
      render: (text, record, index) =>
        !!record.firstDate ? moment(record.firstDate).format('MM/DD/YYYY') : 'n/a',
      sorter: (a, b) => new Date(a.firstDate) - new Date(b.firstDate),
    },
    {
      title: 'Time (Hours)',
      dataIndex: 'time',
      key: 'time',
      sorter: (a, b) => a.time - b.time,
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (text, record, index) => currencyFormatter.format(record.revenue),
      sorter: (a, b) => a.revenue - b.revenue,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record, index) => currencyFormatter.format(record.cost),
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      render: (text, record, index) => currencyFormatter.format(record.profit),
      sorter: (a, b) => a.profit - b.profit,
    },
    {
      title: 'Profit/Hr',
      dataIndex: 'perHour',
      key: 'perHour',
      render: (text, record, index) => currencyFormatter.format(record.profit / record.time),
      sorter: (a, b) => a.profit / a.time - b.profit / b.time,
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      key: 'margin',
      render: (text, record, index) => percentageFormatter(record.profit / record.revenue),
      sorter: (a, b) => a.profit / a.revenue - b.profit / b.revenue,
    },
  ];

  return (
    <ContentContainer loading={loading} loadingTip="Loading Monthly Fee Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>{`Monthly Fee Audit`}</TitleH4>

        <div>
          <RangePicker
            value={dateRange}
            onChange={onChange}
            format="MM/DD/YYYY"
            allowClear={false}
            onOpenChange={openChange}
            open={pickerOpen}
            renderExtraFooter={() => (
              <FlexRow justifyContent="flex-end" alignItems="center">
                <PrimaryButton disabled={!rangeChanged} onClick={onOk}>
                  Ok
                </PrimaryButton>
              </FlexRow>
            )}
          />
        </div>
      </FlexRow>

      <PageContentContainer>
        <GridRow gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr">
          <AvatarCard noAvatar name="Total Time">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && <TitleH4>{companyLevelData.time} Hours</TitleH4>}
            </FlexRow>
          </AvatarCard>

          <AvatarCard noAvatar name="Total Revenue">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && (
                <TitleH4 style={{ color: COLORS.primaryColor }}>
                  {currencyFormatter.format(companyLevelData.revenue)}
                </TitleH4>
              )}
            </FlexRow>
          </AvatarCard>

          <AvatarCard noAvatar name="Total Cost">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && (
                <TitleH4 style={{ color: COLORS.errorColor }}>
                  {currencyFormatter.format(companyLevelData.cost)}
                </TitleH4>
              )}
            </FlexRow>
          </AvatarCard>

          <AvatarCard noAvatar name="Total Profit">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && (
                <TitleH4
                  style={{
                    color: companyLevelData.profit >= 0 ? COLORS.primaryColor : COLORS.errorColor,
                  }}
                >
                  {currencyFormatter.format(companyLevelData.profit)}
                </TitleH4>
              )}
            </FlexRow>
          </AvatarCard>

          <AvatarCard noAvatar name="Avg. Profit / Hr">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && (
                <TitleH4>
                  {currencyFormatter.format(companyLevelData.profit / companyLevelData.time)}
                </TitleH4>
              )}
            </FlexRow>
          </AvatarCard>

          <AvatarCard noAvatar name="Avg. Margin">
            <FlexRow padding="20px" justifyContent="center" alignItems="center">
              {companyLevelData && (
                <TitleH4>
                  {percentageFormatter(companyLevelData.profit / companyLevelData.revenue)}
                </TitleH4>
              )}
            </FlexRow>
          </AvatarCard>
        </GridRow>

        <CardNoTitle>
          <Table
            pagination={false}
            size="small"
            dataSource={
              monthlyClientData && monthlyClientData.sort((a, b) => a.name.localeCompare(b.name))
            }
            rowKey="id"
            style={{ width: '100%' }}
            columns={columns}
          />
        </CardNoTitle>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ reports }) => ({
  reports,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMonthlyAuditData: (payload) => dispatch(fetchMonthlyAuditDataAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyFeeAudit);
